<template>
  <div class="root__">
    <div class="payment-heading">Payment Completed Orders</div>
    <div v-if="paymentDonePackages() == 0">
      <EmptyState />
    </div>
    <div v-else v-for="(item, indx) in getAllPackageDetails" :key="indx" class="root_box">
      <PaymentCompletedComp v-if="item.paymentStatus === 'Done'"  :value="item" />
    </div>
  </div>
</template>
<script>

import { mapMutations, mapState, mapActions } from "vuex";
import { BRow, BDropdown, BButton } from "bootstrap-vue";
import PaymentCompletedComp from "./PaymentCompletedOrdersComp.vue";
import EmptyState from './EmptyStatePaymentsCompletedPackages.vue'

export default {
  components: {
    PaymentCompletedComp,
    BRow,
    BDropdown,
    BButton,
    EmptyState
  },
  data() {
    return {
      len: 0,
    };
  },
  computed: mapState({
    getAllPackageDetails: (state) => {
      return state.shippingPackages.allPaymentsCompletedPackages;
    },
  }),
  async mounted() {
    await this.getShippedItems()
    await this.setMyOrdersNavbar('')
    await this.setSidebarRoute('')
    await this.setNavbarRoute('dashboardLanding')
    await this.paymentDonePackages()
    await this.getPaymentCompletedDate();
  },
  methods: {
    ...mapActions({
      getShippedItems: 'shippingPackages/getShippedItems',
      getPaymentCompletedDate: "shippingPackages/getPaymentCompletedDate",
    }),
    ...mapMutations({
      setMyOrdersNavbar: 'routesStore/SET_MY_ORDERS_NAVBAR',
      setSidebarRoute: 'routesStore/SET_SIDEBAR',
      setNavbarRoute: 'routesStore/SET_NAVBAR'
    }),

    paymentDonePackages() {
      const arr = Object(this.getAllPackageDetails.filter(item => item.paymentStatus === 'Done'))
      return arr.length;
    }
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content: space-around;
}

.customDropdown {
  color: #4385f5;
}

.newbox {
  border: 1px dashed #4385f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  cursor: pointer;
}

.root_box {
  color: #F8F8F8;
}

.payment-heading {
  margin: 2rem;
  font-size: 1.5rem;
  color: #4385f5;
  font-weight: 500;
}
@media screen and (max-width: 1200px){
  .root__{
    padding: 0 1rem 0rem 1rem ;
  }
  .payment-heading{
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .root__ {
    width: 65em;
  }
}
</style>