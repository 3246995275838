<template>
  <div class="root_main">
    <div class="root1" v-if="$mq === 'largeDevices'">
      <div class="root-box" v-b-toggle="`${id__}`">
        <div class="id blue special-border">Package {{ value.packageNo }}</div>
        <div class="date special-border">
          Date:
          <span class="blue">{{formatDate(value.razorpaypayment.createdAt.substring(0,10))}}</span>
        </div>
        <div class="number special-border">
          Amount Paid: <span class="blue">{{ value.finalPrice }}</span>
        </div>
        <div class="details blue" @click="showItemsInPackage">
          View Items
          <span v-if="show == false" class="blue"><i class="fa-solid fa-circle-chevron-down"></i></span>
          <span v-else class="blue"><i class="fa-solid fa-circle-chevron-up"></i></span>
        </div>
      </div>
    </div>

    <!-- collapse part -->
    <b-collapse :id="id__" v-if="show && $mq === 'largeDevices'">
      <div class="inProgress">
        <ConsolidatedItems :id="id__" />
      </div>
    </b-collapse>

    <div v-if="$mq === 'smallDevices' || $mq === 'mediumDevices'">
      <div class="card">
        <div class="left">
          <div class="packageNo">{{ value.packageNo }}</div>
          <div class="date">
            <span class="blue">{{ formatDate(value.razorpaypayment.createdAt.substring(0,10))}}</span>
          </div>
        </div>
        <div class="right">
          <div class="number">
            Amount Paid: <span class="blue">{{ value.finalPrice }}</span>
          </div>
          <div class="view-item blue" @click="showItemsInPackage">View Items
            <span v-if="show == false" class="blue"> <i class="fa-solid fa-circle-chevron-down"></i></span>
            <span v-else class="blue"> <i class="fa-solid fa-circle-chevron-up"></i></span>
          </div>
        </div>
      </div>
      <b-collapse :id="id__" v-if="show" class="collapse" style="display: block">
        <div>
          <ConsolidatedItems :id="id__" />
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import ConsolidatedItems from "../dashboard/MyOrders/ConsolidatedItems.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BCollapse, BButton, VBToggle, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  BRow,
  BCol,
  BLink,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BOverlay,
} from "bootstrap-vue";
import { mapState, mapActions, mapMutations } from "vuex";
import Vue from "vue";
import VueMq from "vue-mq";

Vue.use(VueMq, {
  breakpoints: {
    smallDevices: 600,
    mediumDevices: 1200,
    largeDevices: Infinity,
  },
});

export default {
  components: {
    BRow,
    ConsolidatedItems,
    BCard,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    VBToggle,
    BOverlay,
  },
  props: ["value"],
  data() {
    return {
      showDetails: false,
      show: false,
      packageWithCompletedPayment: ''
    };
  },

  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  computed: {
    id__() {
      return `${this.value.id}`;
    },
    status() {
      const fStatus = Object.values(this.value.selectedItems).filter(
        (item) => item.paymentStatus === "Done"
      );
      if (fStatus.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    ...mapState({
      getPaymentCompletedPackages: state => {
        return state.shippingPackages.allPaymentsCompletedPackages
      }
    }),
  },
  methods: {
    ...mapActions({
    }),
    handleClick() { },
    showItemsInPackage() {
      this.show = !this.show;
    },
    formatDate(date) {
      let datee = new Date(date);
      let monthNameShort = datee.toLocaleString('default', { month: 'short' });
      const [year,month,day] = date.split("-");
      return [day, monthNameShort, year].join("-");
    },
  },
  async mounted() {
    await this.paymentCompletedPackages(this.value.id);
  }
};
</script>

<style scoped>
.root_main {
  background-color: #f8f8f8;
  color: #000000;
}

.root1 {
  background-color: #f8f8f8;
  min-width: 1021px;
  min-height: 118px;
  padding: 2rem;
  display: flex;
  align-items: center;
}

.root-box {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  background: #ffffff;
  height: 53px;
  padding: 2rem;
  font-size: 14px;
  line-height: 21px;
  color: #414141;
  cursor: pointer;
}

.root-box>div {
  max-height: 26px;
  padding: 0 0.3rem;
}

.blue {
  font-weight: 500;
  color: #4385f5;
}

.date {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.special-border {
  border-right: 1px solid #c9c9c9;
}

.status {
  margin: 0.3rem;
}

.orange {
  font-weight: 500;
  color: #e87613;
}

.green {
  font-weight: 500;
  color: #10a531;
}

.details {
  margin-left: auto;
}

/* collapse designs! */
.bttn2 {
  background: #4385f5;
  color: #ffffff;
  min-width: 160px;
  margin-left: 6rem;
  margin-bottom: 3rem;
}

.buttons {
  margin-top: 0.8rem;
}

.rroot {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  padding: 2rem;
  padding-top: 0;
}

.bttn1 {
  background: #d9e7fd;
  color: #4385f5;
  min-width: 160px;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.h1 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #414141;
}

.h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}

.p {
  width: 256px;
  height: 60px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #555555;
}

.list2 {
  margin-bottom: 0.5rem;
}

.val_list2 {
  color: #000000;
  font-weight: 500;
}

.left_ {
  border-right: 1px solid #c9c9c9;
  padding: 0 4rem;
  padding-right: 5rem;
  /* chnge */
}

.right_ {
  padding: 0 4rem;
}

.content_ {
  font-size: 13px;
  width: 374px;
  height: 60px;
  line-height: 20px;
}

.vw {
  font-weight: 500;
  line-height: 20px;
}

.lst {
  display: flex;
  gap: 0.5rem;
  text-align: center;
  width: 400px;
  height: 50px;
}

.lst-1 {
  border-bottom: 1px solid #4b2d2d;
}

.box_image {
  width: 290.31px;
  height: 209.79px;
  background-image: url("../../.././src/assets/yicimages/dashboard/box.png");
  margin-bottom: 3rem;
  margin-top: 2rem;
}

.checkBoxes {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0.6rem;
  width: 359px;
  height: 38px;
  margin-top: 1.5rem;
}

.label_ {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding: 0.6rem 0;
}

.name {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #414141;
}

.label_ div {
  padding: 0 1rem;
}

.selected {
  border: 1px solid #4385f5;
}

.readyforshipping {
  top: 0;
  left: 1;
  z-index: 3;
  position: absolute;
  width: 100%;
}

.card-text-orange {
  color: black;
  background-color: #fef6ef;
}

.card-text-green {
  color: black;
  background-color: #f7fff8;
}

.hover-info:hover {
  transform: scale(1.2);
}

.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 20rem;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 40%;
  left: 50%;
  margin-left: -70px;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}

.card-content {
  margin-top: 1rem;
  font-size: 12px;
}

@media screen and (max-width: 1200px) {
  .card {
    padding: 0.7rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.5vh;
    width: 100%;
    min-height: 85px;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .view-item {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .packageNo {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .shipping-partener-name {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
  }

  .collapse {
    background-color: #f8f8f8;
  }

  .root_1 {
    padding: 0rem 1rem 0rem 1rem;
  }

  .tooltip1 .tooltiptext {
    visibility: hidden;
    width: 20rem;
    border-radius: 6px;
    padding: 1px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 20%;
    left: 50%;
    margin-left: -260px;
  }

  .card-content {
    margin-top: 1rem;
    font-size: 1rem;
  }

  .card-text-orange {
    color: black;
    background-color: #fef6ef;
    font-size: 1.2rem;
  }

  .card-text-green {
    color: black;
    background-color: #f7fff8;
    font-size: 1.2rem;
  }

  .blue {
    font-weight: 500;
    color: #4385f5;
    margin-left: 0.3rem;
  }

  .orange-1 {
    display: flex;
    justify-content: flex-end;
  }

  .green-1 {
    display: flex;
    justify-content: flex-end;
  }

  .hover-info {
    margin-left: 0.3rem;
  }

  .root-box {
    background-color: white;
  }
}

@media screen and (min-width: 1250px) and (max-width: 1400px) {
  .root1 {
    min-width: 65em;
  }
}
</style>